@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font Prudential */
@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-medium_0.ttf);
}

@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-mediumitalic_0.ttf);
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-light_0.ttf);
	font-weight: 300;
}

@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-lightitalic_0.ttf);
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-boldsemcon_0.ttf);
	font-weight: 600;
}

@font-face {
	font-family: "Prudential Modern";
	src: url(assets/fonts/prudentialmodern-bolditalic_0.ttf);
	font-weight: 600;
	font-style: italic;
}
body{
	font-family: "Prudential Modern" !important;
}

@media (orientation: portrait) {
	.infinite-scroll-component__outerdiv {
		border: 1px solid #e9e1e1;
		border-radius: 5px;
		margin: 3px;
	}

	.items-center2{
		display: flex;
		align-items: center;
		margin: 5px;
		padding: 10px;
	}
}

@media (orientation: landscape) {
	.infinite-scroll-component__outerdiv {
		margin-bottom: 20px;
	}

	.infinite-scroll-component {
		display: flex;
		column-gap: 5%;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.items-center2{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-basis: 30%;
	}
}
.input-list{
	margin-left: 8px;
}
img.iconImageOrganization{
	background: no-repeat center transparent;
}
img.iconImageOrganization_load{
	background-image: url(assets/img/loading.gif);
}
.infinite-scroll-component > div{
	padding: 10px;
}
.mss-load{
	color: red;
	cursor: pointer;
	padding: 20px;
	text-align: center;
	text-decoration: underline;
}
.item-list:hover{
	--tw-text-opacity: 1;
	background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}